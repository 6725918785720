import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { PdfColsAtom, PdfDataAtom, titleAtom } from "../../../atom";
import { titles } from "../../../utils/Titles";
import IndustryBenchmark from "./IndustryBenchmark";
import Banner from "../Banner";
import ChurnbyService from "./ChurnbyService";

const Churn = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  var specific_profile = queryParameters.get("profile");
  const setTitle = useSetRecoilState(titleAtom);
  const [active, setActive] = useState(1);
  const setPdfCols = useSetRecoilState(PdfColsAtom);
  const setPdfData = useSetRecoilState(PdfDataAtom);

  const churnText =
    "Parks Associates calculates churn by calculating how many households cancelled a service in the previous 12 months compared to its current subscriber base.\nA service with a low churn rate is doing a good job of keeping subscribers. Those with a high churn rate are at risk of slowing or flat growth. Those services with a churn rate of greater than 100% actively losing subscribers.\nBy better understanding consumer attitudes, motivations, and habits related to churn, service providers can more effectively create service experiences that build natural barriers to exit and bind subscribers tightly to beloved services.";

  const clearParams = () => {
    queryParameters.delete("profile");
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, "", newUrl);
  };

  useEffect(() => {
    setTitle("CHURN");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  useEffect(() => {
    if (specific_profile) setActive(2);
  }, [specific_profile]);

  useEffect(() => {
    setPdfCols(null);
    setPdfData(null);
  }, [active]);

  return (
    <>
      <div className="z-50 sticky top-12">
        <div className="bg-white flex mb-5">
          <button
            onClick={() => {
              setActive(1);
              clearParams();
            }}
            className={`min-w-max border-b-[3px] ${
              active === 1 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-10 font-semibold`}
          >
            Industry Benchmark
          </button>
          <button
            onClick={() => {
              setActive(2);
            }}
            className={`min-w-max border-b-[3px] ${
              active === 2 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-10 font-semibold`}
          >
            Churn by service
          </button>
          <div className="w-full border-b-[3px]"></div>
        </div>
      </div>
      {/* Banner */}
      <Banner heading="Churn" para={churnText} />

      {/* NPS and Churn pages */}
      {active === 1 ? <IndustryBenchmark /> : <ChurnbyService />}
    </>
  );
};

export default Churn;
